import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import EvaluationResultContainer from "components/EvaluationResult/EvaluationResultContainer";
import AnalysisResultContainer from "components/AI/Analysis/AnalysisResultContainer";
import ETTContainer from "components/WorkoutResult/ETTContainer";
import WorkoutResultContainer from "components/WorkoutResult/WorkoutResultContainer";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { currentDuration } from "store/atoms";
import { Container, Row, Col, Button, Nav, Card, Table } from "react-bootstrap";
import PatientInfoBox from "./PatientInfoBox";
import { OPTION_LIST } from "constants/cardiacTargetTypeList";
import { useNavigate } from "react-router-dom";
import { ettRiskGroup } from "constants/monitoring";

const MonitoringDetailContents = ({ searchDate: initialSearchDate, handleGoBack }) => {
    const patientUUID = sessionStorage.getItem("patientUUID");
    const patientUID = sessionStorage.getItem("patientUID");
    const programId = sessionStorage.getItem("programId");
    const cycleId = sessionStorage.getItem("cycleId");
    const [workoutDate, setWorkoutDate] = useState(sessionStorage.getItem("workoutDate"));
    const duration = useRecoilValue(currentDuration);
    const [tab, setTab] = useState("workout");
    const navigate = useNavigate();

    const [searchDate, setSearchDate] = useState(initialSearchDate);

    // const { mutate: workoutMutate, data: workoutResult } = useMutation({
    //     mutationFn: (duration) => fetchData("POST", `/v1/monitoring/workout/${duration}`, { patientUID, searchDate }),
    // });

    // const { mutate: patientInfoMutate, data: patientInfo } = useMutation({
    //     mutationFn: (patientUID) => fetchData("POST", `/v1/monitoring/view/patientInfo`, { patientUID }),
    // });

    const { mutate: evaluationMutate, data: evaluationResult } = useMutation({
        mutationFn: ({ patientUID, workoutDate: searchDate }) => fetchData("POST", "/v1/monitoring/evaluation/day", { patientUID, searchDate }),
    });

    const { mutate: infoMutate, data: infoData } = useMutation({
        mutationFn: ({ patientUID, workoutDate: searchDate }) => fetchData("POST", `/v1/monitoring/view/info`, { patientUID, searchDate }),
    });

    const { mutate: programMutate, data: programData } = useMutation({
        mutationFn: (patientUID) => fetchData("POST", `/v1/monitoring/view/programList`, { patientUID }),
    });

    // useEffect(() => {
    //     patientInfoMutate(patientUID);
    // }, [patientUID]);

    useEffect(() => {
        infoMutate({ patientUID, workoutDate });
    }, [patientUID]);

    // useEffect(() => {
    //     programMutate(patientUID);
    // }, [patientUID]);

    useEffect(() => {
        if (tab === "workout") {
            // workoutMutate(duration);
        } else if (tab === "evaluation") evaluationMutate({ patientUID, workoutDate });
        else if (tab === "ai_anlys") console.log("API Server AI Data Request");
        // eslint-disable-next-line
    }, [tab]);

    return (
        // workoutResult && (
        <Row className="g-2">
            <Col md={4}>
                {/* <PatientInfoBox patientInfo={patientInfo.data} />
                    <ETTContainer programData={programData.data} */}
                <Card className="p-2 mb-4">
                    <Card.Header as="h2">{infoData?.data.patientInfo[0].name}</Card.Header>
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col>{infoData?.data.patientInfo[0].birth}</Col>
                            </Row>
                            <Row>
                                <Col>{infoData?.data.patientInfo[0].phone}</Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>

                <Card className="mb-4">
                    <Card.Body>
                        {/* <Table striped bordered hover size="sm"> */}
                        <div className="mb-3">
                            <h3>재활 프로그램 1회</h3>
                        </div>

                        <div className="mt-6 mb-3">
                            <h4>질환정보</h4>
                        </div>
                        <div className="scrollbar ms-n1 ps-1">
                            <Table bordered className="phoenix-table fs-9 mb-0 border-top">
                                <thead className="bg-body-highlight">
                                    <tr>
                                        <th
                                            colSpan={2}
                                            className="text-center text-body-tertiary align-middle"
                                            style={{ width: "33%", minWidth: "100px" }}
                                        >
                                            심장 질환명
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} className="ps-3">
                                            {infoData?.data?.diseaseInfo?.[0]?.diseaseName || "-"}
                                            <br />
                                            {OPTION_LIST.find((option) => option.no === infoData?.data?.diseaseInfo?.[0]?.targetCategoryNo)?.title}
                                        </td>
                                    </tr>
                                </tbody>
                                <thead className="bg-body-highlight">
                                    <tr>
                                        <th className="text-center text-body-tertiary align-middle" style={{ width: "33%", minWidth: "100px" }}>
                                            발병일(진단일)
                                        </th>
                                        <th className="text-center text-body-tertiary align-middle" style={{ width: "33%", minWidth: "100px" }}>
                                            기준일(퇴원일)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">{infoData?.data?.diseaseInfo?.[0]?.dateOccur || "-"}</td>
                                        <td className="text-center">{infoData?.data?.diseaseInfo?.[0]?.dateBase || "-"}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className="mt-6 mb-3">
                            <h4>운동부하검사정보</h4>
                        </div>
                        <div className="scrollbar ms-n1 ps-1">
                            <Table bordered className="phoenix-table fs-9 mb-0 border-top">
                                <thead className="bg-body-highlight">
                                    <tr>
                                        <th className="text-center text-body-tertiary align-middle" style={{ width: "5%", minWidth: "80px" }}>
                                            구분
                                        </th>
                                        <th className="text-center text-body-tertiary align-middle" style={{ width: "25%", minWidth: "100px" }}>
                                            1차 검사
                                        </th>
                                        <th className="text-center text-body-tertiary align-middle" style={{ width: "25%", minWidth: "100px" }}>
                                            2차 검사
                                        </th>
                                        <th className="text-center text-body-tertiary align-middle" style={{ width: "25%", minWidth: "100px" }}>
                                            3차 검사
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className="text-end text-body-tertiary align-middle  bg-body-highlight">키 (cm)</th>
                                        <td className="text-center">
                                            {infoData?.data?.ettInfo?.[0]?.height ? `${infoData.data.ettInfo[0].height}cm` : "-"}
                                        </td>
                                        <td className="text-center">
                                            {infoData?.data?.ettInfo?.[1]?.height ? `${infoData.data.ettInfo[1].height}cm` : "-"}
                                        </td>
                                        <td className="text-center">
                                            {infoData?.data?.ettInfo?.[2]?.height ? `${infoData.data.ettInfo[2].height}cm` : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-end text-body-tertiary align-middle bg-body-highlight">몸무게 (kg)</th>
                                        <td className="text-center">
                                            {infoData?.data?.ettInfo?.[0]?.weight ? `${infoData.data.ettInfo[0].weight}kg` : "-"}
                                        </td>
                                        <td className="text-center">
                                            {infoData?.data?.ettInfo?.[1]?.weight ? `${infoData.data.ettInfo[1].weight}kg` : "-"}
                                        </td>
                                        <td className="text-center">
                                            {infoData?.data?.ettInfo?.[2]?.weight ? `${infoData.data.ettInfo[2].weight}kg` : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-end text-body-tertiary align-middle  bg-body-highlight">최대 심박수</th>
                                        <td className="text-center">{infoData?.data?.ettInfo?.[0]?.maxHeartRate || "-"}</td>
                                        <td className="text-center">{infoData?.data?.ettInfo?.[1]?.maxHeartRate || "-"}</td>
                                        <td className="text-center">{infoData?.data?.ettInfo?.[2]?.maxHeartRate || "-"}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-end text-body-tertiary align-middle  bg-body-highlight">안정 심박수</th>
                                        <td className="text-center">{infoData?.data?.ettInfo?.[0]?.restingHeartRate || "-"}</td>
                                        <td className="text-center">{infoData?.data?.ettInfo?.[1]?.restingHeartRate || "-"}</td>
                                        <td className="text-center">{infoData?.data?.ettInfo?.[2]?.restingHeartRate || "-"}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-end text-body-tertiary align-middle  bg-body-highlight">METs</th>
                                        <td className="text-center">{infoData?.data?.ettInfo?.[0]?.metsValue || "-"}</td>
                                        <td className="text-center">{infoData?.data?.ettInfo?.[1]?.metsValue || "-"}</td>
                                        <td className="text-center">{infoData?.data?.ettInfo?.[2]?.metsValue || "-"}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-end text-body-tertiary align-middle  bg-body-highlight">위험군</th>
                                        <td className="text-center">
                                            {infoData?.data?.ettInfo?.[0]?.riskGroup === 1
                                                ? "저위험군"
                                                : infoData?.data?.ettInfo?.[0]?.riskGroup === 2
                                                ? "중위험군"
                                                : infoData?.data?.ettInfo?.[0]?.riskGroup === 3
                                                ? "고위험군"
                                                : "-"}
                                        </td>
                                        <td className="text-center">
                                            {infoData?.data?.ettInfo?.[1]?.riskGroup === 1
                                                ? "저위험군"
                                                : infoData?.data?.ettInfo?.[1]?.riskGroup === 2
                                                ? "중위험군"
                                                : infoData?.data?.ettInfo?.[1]?.riskGroup === 3
                                                ? "고위험군"
                                                : "-"}
                                        </td>
                                        <td className="text-center">
                                            {infoData?.data?.ettInfo?.[2]?.riskGroup === 1
                                                ? "저위험군"
                                                : infoData?.data?.ettInfo?.[2]?.riskGroup === 2
                                                ? "중위험군"
                                                : infoData?.data?.ettInfo?.[2]?.riskGroup === 3
                                                ? "고위험군"
                                                : "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className="mt-6 mb-3">
                            <h4>기타질환정보</h4>
                        </div>
                        <div className="scrollbar ms-n1 ps-1">
                            <Table bordered className="phoenix-table fs-9 mb-0 border-top">
                                <thead className="bg-body-highlight">
                                    <tr>
                                        <th className="ps-3 text-body-tertiary align-middle" style={{ width: "33%", minWidth: "100px" }}>
                                            기타 질환명
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="ps-3">{infoData?.data?.diseaseInfo?.[0]?.etc || "-"}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={8}>
                <Nav variant="tabs" defaultActiveKey="workout">
                    <Nav.Item>
                        <Nav.Link
                            active={tab !== "workout"}
                            onClick={() => {
                                if (tab !== "workout") {
                                    setTab("workout");
                                    // workoutMutate(duration);
                                }
                            }}
                        >
                            <p className="fs-7">운동기록</p>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            active={tab !== "evaluation"}
                            onClick={() => {
                                if (tab !== "evaluation") {
                                    setTab("evaluation");
                                    evaluationMutate();
                                }
                            }}
                        >
                            <p className="fs-7">평가기록</p>
                        </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                            <Nav.Link
                                active={tab !== "ai_anlys"}
                                onClick={() => {
                                    if (tab !== "ai_anlys") {
                                        setTab("ai_anlys");
                                        // evaluationMutate();
                                    }
                                }}
                            >
                                <p className="fs-7">AI 분석결과</p>
                            </Nav.Link>
                        </Nav.Item> */}
                </Nav>
                {tab === "workout" ? (
                    // <WorkoutResultContainer workoutResult={workoutResult.data} workoutMutate={workoutMutate} />
                    <WorkoutResultContainer searchDate={searchDate} />
                ) : tab === "evaluation" ? (
                    <EvaluationResultContainer evaluationResult={evaluationResult} />
                ) : tab === "ai_anlys" ? (
                    <AnalysisResultContainer />
                ) : (
                    <div>잘못된 요청입니다.</div>
                )}
                <Button className="w-100" variant="primary" onClick={() => handleGoBack()}>
                    목록
                </Button>
            </Col>
        </Row>
        // )
    );
};

export default MonitoringDetailContents;
