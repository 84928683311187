import App from "App";
import HosiptalList from "components/Management/HosiptalList";
import HosiptalRegister from "components/Management/HosiptalRegister";
import UserSetting from "pages/UserSetting";
import Layout from "pages/Layout";
import Login from "pages/Login";
import Monitoring from "pages/Monitoring";
import MonitoringCNR from "pages/Monitoring_CNR";
import MonitoringDetail from "pages/MonitoringDetail";
import MonitoringDetailCNR from "pages/MonitoringDetail_CNR";
import { RouterProvider, createBrowserRouter, Routes, Route } from "react-router-dom";
import Error404 from "../pages/error/Error404";
import ErrorNotSubDomain from "../pages/error/ErrorNotSubDomain";
import CryptTest from "pages/CryptTest";
import ProtectedRoute from "./ProtectedRoute"; // ProtectedRoute import 추가

import MainLayoutProvider from "providers/MainLayoutProvider";
import MainLayout from "layouts/MainLayout";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";

import EttList from "pages/ett/EttList";
import EttRegister from "pages/ett/EttRegister";
import EttModify from "pages/ett/EttModify";
import EttDetail from "pages/ett/EttDetail";
import { IdxPatientListMonitoring, IdxPatientListMonitoringCNR, IdxPatientListEtt } from "pages/patients/index";
import {
    IdxOperatorSetting,
    IdxOperatorRegister,
    IdxOperatorDetail,
    IdxStaffSetting,
    IdxStaffRegister,
    IdxStaffDetail,
    IdxMyOperatorDetail,
    IdxOperatorForStaffRegister,
    IdxOperatorForStaffDetail,
    IdxOperatorForStaffSetting,
    IdxMyStaffDetail,
} from "pages/settings/index";

const Router = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <App />,
            children: [
                {
                    path: "/",
                    element: (
                        <AuthSimpleLayout>
                            <Login layout="simple" />
                        </AuthSimpleLayout>
                    ),
                },
                {
                    path: "/not-found",
                    element: <ErrorNotSubDomain />,
                },
                {
                    element: (
                        <ProtectedRoute>
                            <MainLayoutProvider>
                                {/* <Layout /> */}
                                <MainLayout />
                            </MainLayoutProvider>
                        </ProtectedRoute>
                    ),
                    children: [
                        {
                            path: "/crypt",
                            element: <CryptTest />,
                        },
                        {
                            path: "/ett/patients",
                            element: <IdxPatientListEtt />,
                        },
                        {
                            path: "/ett/list",
                            element: <EttList />,
                        },
                        {
                            path: "/ett/register",
                            element: <EttRegister />,
                        },
                        {
                            path: "/ett/modify",
                            element: <EttModify />,
                        },
                        {
                            path: "/ett/detail",
                            element: <EttDetail />,
                        },
                        {
                            path: "/monitoring/patients",
                            element: <IdxPatientListMonitoring />,
                        },
                        {
                            path: "/monitoring",
                            element: <Monitoring />,
                        },
                        {
                            path: "/monitoring/detail",
                            element: <MonitoringDetail />,
                        },
                        {
                            path: "/hospital/list",
                            element: <HosiptalList />,
                        },
                        {
                            path: "/hospital/register",
                            element: <HosiptalRegister />,
                        },

                        //C&R 임상시험용
                        {
                            path: "/monitoring-cnr-h1/patients",
                            element: <IdxPatientListMonitoringCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h2/patients",
                            element: <IdxPatientListMonitoringCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h3/patients",
                            element: <IdxPatientListMonitoringCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h4/patients",
                            element: <IdxPatientListMonitoringCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h1",
                            element: <MonitoringCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h2",
                            element: <MonitoringCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h3",
                            element: <MonitoringCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h4",
                            element: <MonitoringCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h1/detail",
                            element: <MonitoringDetailCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h2/detail",
                            element: <MonitoringDetailCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h3/detail",
                            element: <MonitoringDetailCNR />,
                        },
                        {
                            path: "/monitoring-cnr-h4/detail",
                            element: <MonitoringDetailCNR />,
                        },

                        // {
                        //     path: "/operator-setting",
                        //     element: <OperatorSetting />,
                        // },
                        // {
                        //     path: "/operator-setting/register",
                        //     element: <OperatorRegister />,
                        // },
                        // {
                        //     path: "/operator-setting/detail",
                        //     element: <OperatorDetail />,
                        // },
                        // {
                        //     path: "/staff-setting",
                        //     element: <StaffSetting />,
                        // },
                        // {
                        //     path: "/staff-setting/register",
                        //     element: <StaffRegister />,
                        // },
                        // {
                        //     path: "/staff-setting/detail",
                        //     element: <StaffDetail />,
                        // },

                        // 관리자 메뉴
                        {
                            path: "/operator-setting",
                            element: <IdxOperatorSetting />,
                        },
                        {
                            path: "/operator-setting/register",
                            element: <IdxOperatorRegister />,
                        },
                        {
                            path: "/operator-setting/detail",
                            element: <IdxOperatorDetail />,
                        },
                        {
                            path: "/staff-setting",
                            element: <IdxStaffSetting />,
                        },
                        {
                            path: "/staff-setting/register",
                            element: <IdxStaffRegister />,
                        },
                        {
                            path: "/staff-setting/detail",
                            element: <IdxStaffDetail />,
                        },
                        // 담당교수 메뉴
                        {
                            path: "/operator-my-info",
                            element: <IdxMyOperatorDetail />,
                        },
                        {
                            path: "/staff-lower-setting/register",
                            element: <IdxOperatorForStaffRegister />,
                        },
                        {
                            path: "/staff-lower-setting",
                            element: <IdxOperatorForStaffSetting />,
                        },
                        {
                            path: "/staff-lower-setting/detail",
                            element: <IdxOperatorForStaffDetail />,
                        },
                        // 실무자 메뉴
                        {
                            path: "/staff-my-info",
                            element: <IdxMyStaffDetail />,
                        },

                        {
                            path: "/user-setting",
                            element: <UserSetting />,
                        },

                        { path: "*", element: <Error404 /> }, // 404 라우트를 추가
                    ],
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
};

export default Router;
