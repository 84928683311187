import { Button, ButtonGroup } from "react-bootstrap";
import { useState } from "react";
import DailyWorkoutResult from "./DailyWorkoutResult";
import WeeklyWorkoutResult from "./WeeklyWorkoutResult";

const WorkoutResultContainer = ({ searchDate }) => {
    const [duration, setDuration] = useState("weeks06");

    const handleClickDuration = (selectedDuration) => {
        setDuration(selectedDuration);
    };

    const TAB_LIST = [
        { duration: "day", title: "일별현황" },
        { duration: "weeks03", title: "3주현황" },
        { duration: "weeks06", title: "6주현황" },
        { duration: "weeks12", title: "12주현황" },
    ];

    return (
        <div>
            <ButtonGroup className="mb-3">
                {TAB_LIST.map((tab, index) => (
                    <Button
                        key={index}
                        variant={duration === tab.duration ? "primary" : "outline-secondary"}
                        onClick={() => handleClickDuration(tab.duration)}
                    >
                        {tab.title}
                    </Button>
                ))}
            </ButtonGroup>
            {duration === "day" ? (
                <DailyWorkoutResult searchDate={searchDate} />
            ) : (
                <WeeklyWorkoutResult searchDate={searchDate} weeks={duration.replace("weeks", "")} />
            )}
        </div>
    );
};

export default WorkoutResultContainer;
