import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import fetchData from "apis/fetchData";
import { getTranslatedMessage } from "constants/alertMesseage";

const useCounselingForm = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/management/regist/counsel", requestData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["counselingList"] });
            const translatedMessage = getTranslatedMessage("REGISTRATION_SUCCESS");
            toast.success(translatedMessage);
        },
        onError: (error) => {
            console.error("Error submitting counseling:", error);
            const translatedMessage = getTranslatedMessage("REGISTRATION_FAILED");
            toast.error(translatedMessage);
        },
    });
};

export default useCounselingForm;
