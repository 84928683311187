import { useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const StaffListTable = ({ staffList }) => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredStaffList, setFilteredStaffList] = useState(staffList);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = staffList.filter(({ operatorName, name, userId, phone, comments }) =>
            `${operatorName} ${name} ${userId} ${phone} ${comments}`.toLowerCase().includes(lowerCaseSearchTerm),
        );
        setFilteredStaffList(filtered);
    };

    return (
        <div className="mt-6">
            <h3 className="mb-2">실무자</h3>
            <Form onSubmit={handleSearchSubmit} className="d-flex mb-3">
                <Form.Control
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    maxLength={50}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="me-2 w-50"
                />
                <Button type="submit">검색</Button>
            </Form>
            <div className="scrollbar ms-n1 ps-1">
                <Table bordered hover className="phoenix-table fs-9 mb-0 border-top">
                    <thead className="bg-body-highlight">
                        <tr>
                            <th className="text-center text-body-tertiary" style={{ width: "6%", minWidth: "30px" }}>
                                NO
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}>
                                담당 교수
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}>
                                이름
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}>
                                아이디
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}>
                                연락처
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "18%", minWidth: "100px" }}>
                                비고
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(filteredStaffList?.length ?? 0) > 0 ? (
                            filteredStaffList.map(({ no, operatorName, name, userId, phone, comments, UUID }, index) => (
                                <tr key={no} onClick={() => navigate(`/staff-setting/detail?id=${UUID}`)} style={{ cursor: "pointer" }}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">{operatorName ? operatorName : "-"}</td>
                                    <td className="text-center" style={{ fontWeight: "bold" }}>
                                        {name}
                                    </td>
                                    <td className="text-center">{userId}</td>
                                    <td className="text-center">{phone}</td>
                                    <td className="text-center">{comments ? comments : "-"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">
                                    조회된 검색결과가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <Row className="mt-3">
                <Col className="text-end">
                    <Button onClick={() => navigate("/staff-setting/register")} variant="primary">
                        등록하기
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default StaffListTable;
