// import Avatar from "components/base/Avatar";
import { Dropdown, Modal, Nav, Button, Badge } from "react-bootstrap";
// import avatar57 from "assets/img/team/40x40/57.webp";
// import ProfileDropdownMenu from "./ProfileDropdownMenu";
// import NineDotMenu from "./NineDotMenu";
// import { useAppContext } from "providers/AppProvider";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
// import NotificationDropdownMenu from "./NotificationDropdownMenu";
import ThemeToggler from "components/common/ThemeToggler";
import { useState, useEffect } from "react";
// import DropdownSearchBox from "components/common/DropdownSearchBox";
// import SearchResult from "components/common/SearchResult";
import { jwtDecode } from "jwt-decode"; // Import without destructuring
import { logout } from "utils/logout";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { getTranslatedMessage } from "constants/alertMesseage";

const NavItems = () => {
    // const {
    //     config: { navbarPosition },
    // } = useAppContext();
    // const [openSearchModal, setOpenSearchModal] = useState(false);

    const [username, setUsername] = useState("사용자");
    const [totalExtendedTime, setTotalExtendedTime] = useState(0);
    const navigate = useNavigate();

    const [remainingTime, setRemainingTime] = useState(() => {
        const savedTime = sessionStorage.getItem("remainingTime");
        return savedTime ? parseInt(savedTime, 10) : 900;
    });

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if (token && typeof token === "string") {
            // 토큰 유효성과 타입 체크
            try {
                const decoded = jwtDecode(token);
                setUsername(decoded.name || "사용자");
            } catch (error) {
                console.error("토큰 디코딩 실패:", error);
                // 토큰이 유효하지 않은 경우 로그아웃 처리
            }
        }

        // 자동 로그아웃 처리
        const timer = setInterval(() => {
            setRemainingTime((prev) => {
                const newTime = prev === 0 ? 0 : prev - 1;
                sessionStorage.setItem("remainingTime", newTime.toString()); // 매 초마다 시간 저장
                if (newTime === 0) {
                    logout(navigate);
                }
                return newTime;
            });
        }, 1000);

        // 컴포넌트 언마운트 또는 페이지 이동 시 남은 시간 저장
        const saveRemainingTime = () => {
            sessionStorage.setItem("remainingTime", remainingTime.toString());
        };

        window.addEventListener("beforeunload", saveRemainingTime);

        return () => {
            clearInterval(timer);
            window.removeEventListener("beforeunload", saveRemainingTime);
        };
    }, [remainingTime, navigate]); // remainingTime을 의존성 배열에 추가

    const extendTime = () => {
        const newRemainingTime = remainingTime + 900;
        if (newRemainingTime <= 7200) {
            setRemainingTime(newRemainingTime);
            setTotalExtendedTime((prev) => prev + 900);
            sessionStorage.setItem("remainingTime", newRemainingTime.toString()); // 세션에 남은시간 저장
        } else {
            const translatedMessage = getTranslatedMessage("TIME_EXTENSION_LIMIT");
            toast.error(translatedMessage);
        }
    };

    const formatTime = (seconds) => {
        const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
        const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
        const secs = String(seconds % 60).padStart(2, "0");
        return `${hrs}:${mins}:${secs}`;
    };

    return (
        <div className="navbar-nav navbar-nav-icons flex-row">
            <Nav.Item>
                <ThemeToggler className="px-2" />
            </Nav.Item>
            {/* <Nav.Item
                className={classNames({
                    "d-lg-none": navbarPosition === "vertical" || navbarPosition === "dual",
                })}
            >
                <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
                    <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
                </Nav.Link>
            </Nav.Item> */}
            {/* <Nav.Item>
                <Dropdown autoClose="outside" className="h-100">
                    <Dropdown.Toggle as={Link} to="#!" className="dropdown-caret-none nav-link h-100" variant="">
                        <FeatherIcon icon="bell" size={20} />
                    </Dropdown.Toggle>
                    <NotificationDropdownMenu />
                </Dropdown>
            </Nav.Item> */}
            {/* <Nav.Item>
                <Dropdown autoClose="outside" className="h-100">
                    <Dropdown.Toggle as={Link} to="#!" className="dropdown-caret-none nav-link h-100" variant="">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                            <circle cx="2" cy="8" r="2" fill="currentColor"></circle>
                            <circle cx="2" cy="14" r="2" fill="currentColor"></circle>
                            <circle cx="8" cy="8" r="2" fill="currentColor"></circle>
                            <circle cx="8" cy="14" r="2" fill="currentColor"></circle>
                            <circle cx="14" cy="8" r="2" fill="currentColor"></circle>
                            <circle cx="14" cy="14" r="2" fill="currentColor"></circle>
                            <circle cx="8" cy="2" r="2" fill="currentColor"></circle>
                            <circle cx="14" cy="2" r="2" fill="currentColor"></circle>
                        </svg>
                    </Dropdown.Toggle>
                    <NineDotMenu />
                </Dropdown>
            </Nav.Item> */}
            {/* <Nav.Item>
                <Dropdown autoClose="outside" className="h-100">
                    <Dropdown.Toggle
                        as={Link}
                        to="#!"
                        className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
                        variant=""
                    >
                        <Avatar src={avatar57} size="l" />
                    </Dropdown.Toggle>
                    <ProfileDropdownMenu />
                </Dropdown>
            </Nav.Item> */}

            <div className="px-3 mt-1">
                <div className="justify-content-between row">
                    <div className="text-center border-translucent pt-1 pb-xxl-0 col-xxl-4 col-md-4 col-4" style={{ width: "120px" }}>
                        <div className="fw-semibold text-center">{username} 님</div>
                    </div>
                    <div
                        className="text-center border-translucent pt-1 pb-xxl-0 col-xxl-4 col-md-4 col-4 border-start-xxl border-start"
                        style={{ width: "120px" }}
                    >
                        <div className="fw-semibold text-center">
                            <span className="cursor-pointer" onClick={() => extendTime()}>
                                <FeatherIcon className="mb-1" icon="clock" size={15} />
                                <span className="notranslate ms-1 mb-1">{formatTime(remainingTime)}</span>
                            </span>
                        </div>
                    </div>
                    <div
                        className="text-center border-translucent pt-1 pb-xxl-0 col-xxl-4 col-md-4 col-4 border-start-xxl border-start"
                        style={{ width: "120px" }}
                    >
                        <div className="fw-semibold text-center">
                            <Link onClick={() => logout(navigate)}>Logout</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Modal show={openSearchModal} onHide={() => setOpenSearchModal(false)} className="search-box-modal mt-15">
                <Modal.Body className="p-0 bg-transparent">
                    <DropdownSearchBox className="navbar-top-search-box" inputClassName="rounded-pill" size="lg" style={{ width: "auto" }}>
                        <SearchResult />
                    </DropdownSearchBox>
                </Modal.Body>
            </Modal> */}
        </div>
    );
};

export default NavItems;
