import React from "react";
import PatientListMonitoring from "components/patients/monitoring/PatientListPage";
import PatientListMonitoringCNR from "components/patients/monitoring/PatientListPage_CNR";
import PatientListEtt from "components/patients/ett/PatientListPage";

export const IdxPatientListMonitoring = () => {
    return <PatientListMonitoring />;
};

//C&R 임상시험
export const IdxPatientListMonitoringCNR = () => {
    return <PatientListMonitoringCNR />;
};

export const IdxPatientListEtt = () => {
    return <PatientListEtt />;
};
