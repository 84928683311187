import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import MonitoringTable from "components/Monitoring/MonitoringTable_CNR";
import { EMPTY_INPUT, INVALID_PHONE_NUMBER_LENGTH, NAME_CHECK } from "constants/errorMessage";
import { TODAY } from "constants/monitoring";
import { NAME_REGEX, NUMBER_ONLY_REGEX } from "constants/regex";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { currentDate } from "store/atoms";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import checkDateValidation from "utils/checkDateValidation";
import checkIsEmpty from "utils/checkIsEmpty";
// import DatePicker from "components/base/DatePicker";
import MuiDatePicker from "components/base/MuiDatePicker";
import { toast } from "sonner";
import useConfirmDialog from "hooks/useConfirmDialog";
import PatientInfoRow from "components/PatientMng/PatientInfoRow";
import { getTranslatedMessage } from "constants/alertMesseage";

const defaultSearchParams = {
    name: "",
    birth: TODAY,
    phoneUnit: {
        first: "010",
        middle: "",
        last: "",
    },
    searchDateStart: TODAY,
    searchDateEnd: TODAY,
    isOtherDoctor: false,
};

//C&R 임상시험
const Monitoring = () => {
    const navigate = useNavigate();
    const [searchResult, setSearchResult] = useState(null);

    const [patientInfo, setPatientInfo] = useState(false);
    const showConfirmDialog = useConfirmDialog();
    const [searchParams, setSearchParams] = useState(defaultSearchParams);

    const { mutate, isPending } = useMutation({
        mutationKey: ["patientResult"],
        mutationFn: (requestData) => fetchData("POST", "/v1/monitoring/patient", requestData),
        onSuccess: ({ data }) => {
            setSearchResult(data?.list);
            setPatientInfo(data?.patientInfo);
            sessionStorage.setItem("searchResult", JSON.stringify(data?.list));
            sessionStorage.setItem("patientUUID", data?.patientInfo.patientUUID);
            sessionStorage.setItem("patientUID", data?.patientInfo.patientUID);
        },
    });

    useEffect(() => {
        const patientUID = sessionStorage.getItem("patientUID");
        if (patientUID) {
            setSearchParams({
                patientUID: patientUID,
            });

            // 자동 검색 실행
            mutate({
                patientUID: patientUID,
            });
        }
    }, [mutate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, birth, phoneUnit, searchDateStart, searchDateEnd, isOtherDoctor } = searchParams;
        const phone = phoneUnit.first + phoneUnit.middle + phoneUnit.last;

        if (!checkIsEmpty(name, phoneUnit.middle, phoneUnit.last, birth)) {
            const translatedMessage = getTranslatedMessage("EMPTY_INPUT");
            toast.error(translatedMessage);
            return;
        }

        if (!NAME_REGEX.test(name)) {
            const translatedMessage = getTranslatedMessage("NAME_MIN_LENGTH_REQUIRED");
            toast.error(translatedMessage);
            return;
        }

        if (
            !(
                NUMBER_ONLY_REGEX.test(phoneUnit.middle) &&
                NUMBER_ONLY_REGEX.test(phoneUnit.last) &&
                phoneUnit.middle.length >= 3 &&
                phoneUnit.last.length === 4
            )
        ) {
            const translatedMessage = getTranslatedMessage("INVALID_PHONE_NUMBER_LENGTH");
            toast.error(translatedMessage);
            return;
        }

        const isValidDate = checkDateValidation(searchDateStart, searchDateEnd, birth);
        if (!isValidDate) return;

        sessionStorage.setItem("searchValue", JSON.stringify(searchParams));

        mutate({ name, birth, phone, searchDateStart, searchDateEnd, otherDoctor: isOtherDoctor });
    };

    useEffect(() => {
        const savedSearchValue = sessionStorage.getItem("searchValue");

        if (savedSearchValue) {
            const parsedSearchValue = JSON.parse(savedSearchValue);
            setSearchParams(parsedSearchValue);

            const { name, birth, phoneUnit, searchDateStart, searchDateEnd, isOtherDoctor } = parsedSearchValue;
            const phone = phoneUnit.first + phoneUnit.middle + phoneUnit.last;
            mutate({ name, birth, phone, searchDateStart, searchDateEnd, otherDoctor: isOtherDoctor });
        }
    }, [mutate]);

    const handleReset = () => {
        setSearchParams(defaultSearchParams);
        setSearchResult(null);
        setPatientInfo(false);
        sessionStorage.removeItem("searchValue");
        sessionStorage.removeItem("searchResult");
        sessionStorage.removeItem("patientUUID");
    };

    const handleSwitchChange = (e) => {
        const isChecked = e.target.checked;
        setSearchParams((prev) => ({ ...prev, isOtherDoctor: isChecked }));
    };

    return (
        <>
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>환자 모니터링</h2>
                    </div>
                    <hr className="bg-body-secondary mb-3" />
                </Row>
                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        {/* <div id="width_relative_to_parent_" className="p-4 border-bottom bg-body hover-actions-trigger card-header">
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formPatientName">
                                        <Form.Label column sm={5}>
                                            환자명 *
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            autoComplete="off"
                                            value={searchParams.name}
                                            onChange={(e) => setSearchParams((prev) => ({ ...prev, name: e.target.value }))}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formBirthDate">
                                        <Form.Label column sm={6}>
                                            생년월일 *
                                        </Form.Label>
                                        <MuiDatePicker
                                            maxDate={TODAY}
                                            onChange={(value) => {
                                                setSearchParams((prev) => ({ ...prev, birth: value }));
                                            }}
                                            value={searchParams.birth}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formPhoneNumber">
                                        <Form.Label column sm={7}>
                                            핸드폰번호 *
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Select
                                                className="w-10"
                                                value={searchParams.phoneUnit.first}
                                                onChange={(e) =>
                                                    setSearchParams((prev) => ({ ...prev, phoneUnit: { ...prev.phoneUnit, first: e.target.value } }))
                                                }
                                            >
                                                <option>010</option>
                                                <option>011</option>
                                                <option>016</option>
                                                <option>017</option>
                                                <option>018</option>
                                                <option>019</option>
                                            </Form.Select>
                                            <Form.Control
                                                type="text"
                                                autoComplete="off"
                                                value={searchParams.phoneUnit.middle}
                                                maxLength={4}
                                                onChange={(e) =>
                                                    setSearchParams((prev) => ({ ...prev, phoneUnit: { ...prev.phoneUnit, middle: e.target.value } }))
                                                }
                                                required
                                            />
                                            <Form.Control
                                                type="text"
                                                autoComplete="off"
                                                value={searchParams.phoneUnit.last}
                                                maxLength={4}
                                                onChange={(e) =>
                                                    setSearchParams((prev) => ({ ...prev, phoneUnit: { ...prev.phoneUnit, last: e.target.value } }))
                                                }
                                                required
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Row>

                                <Row className="p-2">
                                    <Col className="p-2">
                                        <Form.Group as={Col} controlId="formStartDate">
                                            <Form.Label column sm={5}>
                                                운동기간 시작
                                            </Form.Label>
                                            <MuiDatePicker
                                                maxDate={TODAY}
                                                onChange={(value) => {
                                                    setSearchParams((prevState) => ({ ...prevState, searchDateStart: value }));
                                                }}
                                                value={searchParams.searchDateStart}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={1} className="p-2 text-center pt-7">
                                        <h3 className="fw-medium">~</h3>
                                    </Col>
                                    <Col className="p-2">
                                        <Form.Group as={Col} controlId="formEndDate">
                                            <Form.Label column sm={4}>
                                                운동기간 끝
                                            </Form.Label>
                                            <MuiDatePicker
                                                maxDate={TODAY}
                                                onChange={(value) => {
                                                    setSearchParams((prevState) => ({ ...prevState, searchDateEnd: value }));
                                                }}
                                                value={searchParams.searchDateEnd}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="p-2">
                                    <Col className="p-2">
                                        <Button variant="secondary" onClick={handleReset} className="me-2 w-30">
                                            초기화
                                        </Button>
                                    </Col>
                                    <Col md="auto" className="p-2">
                                        <Form.Check
                                            type="switch"
                                            id="defaultSwitch"
                                            label="다른 담당교수"
                                            className="mt-2"
                                            checked={searchParams.isOtherDoctor}
                                            onChange={handleSwitchChange}
                                        />
                                    </Col>
                                    <Col xs lg="3" className="p-2">
                                        <Button className="w-100" variant="primary" type="submit">
                                            검색
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div> */}
                        <div className="p-0 card-body">
                            <div className="p-4">
                                <PatientInfoRow />
                                <MonitoringTable
                                    patientInfo={patientInfo}
                                    isPending={isPending}
                                    searchResult={searchResult}
                                    isOtherDoctor={searchParams.isOtherDoctor}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Monitoring;
