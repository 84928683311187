import { useEffect, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getUserRoleLevel from "./getUserRoleLevel";
import { routes } from "sitemap";
import { toast } from "sonner";
import { jwtDecode } from "jwt-decode";
import encode from "jwt-encode";

const tkk = process.env.REACT_APP_TKK;

//sitemap.jsx에 메뉴접근 권한 체크
export const useUrlChangeEffect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [tokenUpdated, setTokenUpdated] = useState(false);

    const checkUserAccess = useCallback((path) => {
        // 로그인 페이지('/')는 항상 접근 가능
        if (path === "/") {
            return true;
        }

        // 사용자 권한 조회
        const userRole = getUserRoleLevel();

        // 경로 구조 평탄화
        const flattenedRoutes = routes.flatMap((category) =>
            category.pages.flatMap((page) =>
                page.pages
                    ? [{ ...page, parent: category }, ...page.pages.map((subPage) => ({ ...subPage, parent: page }))]
                    : { ...page, parent: category },
            ),
        );

        // 경로패턴 속성을 사용하여 일치하는 경로 찾기
        const matchedRoute = flattenedRoutes.find((route) => {
            if (route.path === path) return true;
            if (route.pathPattern) {
                const pattern = new RegExp("^" + route.pathPattern.replace(/\*/g, ".*") + "($|\\?)");
                const isMatch = pattern.test(path);
                return isMatch;
            }
            return false;
        });

        if (matchedRoute) {
            // 현재 경로의 roleLvl 확인
            if (matchedRoute.roleLvl && !matchedRoute.roleLvl.includes(userRole)) {
                return false;
            }

            // 부모 경로의 roleLvl 확인
            let currentParent = matchedRoute.parent;
            while (currentParent) {
                if (currentParent.roleLvl && !currentParent.roleLvl.includes(userRole)) {
                    return false;
                }
                currentParent = currentParent.parent;
            }
            return true;
        }

        // 일치하는 경로를 찾을 수 없는 경우 접근 허용 (404 페이지로 라우팅되도록)
        return true;
    }, []);

    //C&R 임상시험
    const updateHospitalId = useCallback((newHospitalId) => {
        return new Promise((resolve) => {
            const token = sessionStorage.getItem("token");
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    if (decodedToken.hospitalId !== newHospitalId) {
                        const updatedHospitalId = {
                            ...decodedToken,
                            hospitalId: newHospitalId,
                        };
                        const newToken = encode(updatedHospitalId, tkk);
                        sessionStorage.setItem("token", newToken);
                        setTokenUpdated((prev) => !prev); // 토큰 업데이트 상태 변경
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                } catch (error) {
                    console.error("Error updating token:", error);
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        });
    }, []);

    //url 변경시(IN/OUT) 이벤트 처리 관리하는곳, 권한체크도 함께
    const handleUrlChange = useCallback(() => {
        const currentPath = location.pathname + location.search;
        const previousPath = sessionStorage.getItem("previousPath");

        if (previousPath !== currentPath) {
            if (!checkUserAccess(currentPath)) {
                console.log("접근 권한이 없습니다.");
                navigate(previousPath || "/"); // 이전 페이지 또는 홈으로 리다이렉트
                return;
            }

            if (!previousPath?.startsWith("/ett") && currentPath.startsWith("/ett")) {
                // console.log("ett 영역 진입");
            }

            if (previousPath?.startsWith("/ett") && !currentPath.startsWith("/ett")) {
                // console.log("ett 영역 이탈");
                sessionStorage.removeItem("patientUID");
            }

            if (previousPath?.startsWith("/monitoring") && !currentPath.startsWith("/monitoring")) {
                // console.log("monitoring 영역 이탈");
                sessionStorage.removeItem("patientUID");
                sessionStorage.removeItem("patientUUID");
                sessionStorage.removeItem("searchValue");
                sessionStorage.removeItem("searchResult");
            }

            //C&R 임상시험
            if (!previousPath?.startsWith("/monitoring-cnr-h1") && currentPath.startsWith("/monitoring-cnr-h1")) {
                // console.log("monitoring-cnr-h1 영역 진입");
                const hospitalId = 1;
                updateHospitalId(hospitalId).then((updated) => {
                    if (updated) {
                        sessionStorage.setItem("pickHospitalId", hospitalId);
                        window.location.href = currentPath;
                    }
                });
            }

            if (!previousPath?.startsWith("/monitoring-cnr-h2") && currentPath.startsWith("/monitoring-cnr-h2")) {
                // console.log("monitoring-cnr-h2 영역 진입");
                const hospitalId = 2;
                updateHospitalId(hospitalId).then((updated) => {
                    if (updated) {
                        sessionStorage.setItem("pickHospitalId", hospitalId);
                        window.location.href = currentPath;
                    }
                });
            }

            if (!previousPath?.startsWith("/monitoring-cnr-h3") && currentPath.startsWith("/monitoring-cnr-h3")) {
                // console.log("monitoring-cnr-h3 영역 진입");
                const hospitalId = 3;
                updateHospitalId(hospitalId).then((updated) => {
                    if (updated) {
                        sessionStorage.setItem("pickHospitalId", hospitalId);
                        window.location.href = currentPath;
                    }
                });
            }

            if (!previousPath?.startsWith("/monitoring-cnr-h4") && currentPath.startsWith("/monitoring-cnr-h4")) {
                // console.log("monitoring-cnr-h4 영역 진입");
                const hospitalId = 4;
                updateHospitalId(hospitalId).then((updated) => {
                    if (updated) {
                        sessionStorage.setItem("pickHospitalId", hospitalId);
                        window.location.href = currentPath;
                    }
                });
            }
        }
    }, [location.pathname, navigate, checkUserAccess]);

    // URL 변경 감지
    useEffect(() => {
        handleUrlChange();
    }, [handleUrlChange]);

    // 브라우저 뒤로가기/앞으로가기 처리
    useEffect(() => {
        // 페이지 이동 감지 함수 등록
        window.addEventListener("popstate", handleUrlChange);
        // 컴포넌트가 화면에서 사라질 때 실행될 정리 함수
        return () => {
            // 등록한 감지 함수 제거
            window.removeEventListener("popstate", handleUrlChange);
        };
    }, [handleUrlChange]);
};
