/**
 * 권한별로 로그인 후 이동경로 관리
 * 필요에 따라 다른 역할의 경로를 추가하세요
 **/
export const LOGIN_AFTER_ROLE_PATHS = {
    0: "/operator-setting",
    1: "/ett/patients",
    2: "/ett/patients",
    3: "/monitoring-cnr-h1/patients",
};

/**
 * 로고 클릭시 권한별 이동경로 관리
 * 필요에 따라 다른 역할의 경로를 추가하세요
 **/
export const LOGO_ROLE_PATHS = {
    0: "/operator-setting",
    1: "/ett/patients",
    2: "/ett/patients",
    3: "/monitoring-cnr-h1/patients",
};

export const DEFAULT_PATH = "/ett/patients";
