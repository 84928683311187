import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Pagination, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { usePatients } from "../../../hooks/patients/usePatients";
import MonitoringVerificationModal from "../../Monitoring/MonitoringVerificationModal";
import PatientList from "./PatientList";
import SearchBar from "./SearchBar";

//C&R 임상시험
const PatientListPage_CNR = () => {
    const { patients, loading, error, page, pageLimit, searchData, updatePage, updatePageLimit, updateSearchData, refetch } = usePatients(); // 환자목록 조회
    const [listTypeStatus, setListTypeStatus] = useState("ALL");
    const navigate = useNavigate();
    const location = useLocation();
    const [selectPtInfo, setSelectPtInfo] = useState("");
    const shouldFetchRef = useRef(false);
    const hospitalId = sessionStorage.getItem("pickHospitalId");

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const pageFromUrl = parseInt(params.get("page")) || 1;
        const listTypeFromUrl = params.get("listType") || "ALL";
        const searchNameFromUrl = params.get("searchName") || "";
        const searchPhoneFromUrl = params.get("searchPhone") || "";
        const searchBirthFromUrl = params.get("searchBirth") || "";

        updatePage(pageFromUrl);
        setListTypeStatus(listTypeFromUrl);

        updateSearchData({
            listType: listTypeFromUrl,
            searchName: searchNameFromUrl,
            searchPhone: searchPhoneFromUrl,
            searchBirth: searchBirthFromUrl,
        });

        shouldFetchRef.current = true;
    }, [location.search]);

    useEffect(() => {
        if (shouldFetchRef.current) {
            refetch();
            shouldFetchRef.current = false;
        }
    }, [refetch, searchData, page]);

    const handleSearch = (newSearchData) => {
        const updatedSearchData = { ...newSearchData, listType: listTypeStatus };
        updateSearchData(updatedSearchData);
        updateUrlParams(1, listTypeStatus, updatedSearchData);
    };

    const handleReset = () => {
        const updatedSearchData = { listType: listTypeStatus };
        updateSearchData(updatedSearchData);
        updateUrlParams(page, listTypeStatus, {});
    };

    const updateUrlParams = useCallback(
        (pg, listType, searchParams) => {
            const params = new URLSearchParams();
            params.set("page", pg.toString());
            params.set("listType", listType);
            if (searchParams.searchName) params.set("searchName", searchParams.searchName);
            if (searchParams.searchPhone) params.set("searchPhone", searchParams.searchPhone);
            if (searchParams.searchBirth) params.set("searchBirth", searchParams.searchBirth);
            navigate(`${location.pathname}?${params.toString()}`, { replace: true });
        },
        [navigate, location],
    );

    const handlePageChange = (newPage) => {
        updatePage(newPage);
        updateUrlParams(newPage, listTypeStatus, searchData);
    };

    const handleListTypeChange = (newListType) => {
        const updatedSearchData = { ...searchData, listType: newListType };
        updateSearchData(updatedSearchData);
        setListTypeStatus(newListType);
        updateUrlParams(1, newListType, updatedSearchData);
    };

    //페이징 생성 : 조회된 전체 건수 사용
    const renderPageNumbers = () => {
        const totalPages = Math.ceil((patients?.searchCount || 0) / pageLimit);
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <Pagination.Item key={i} active={i === page} onClick={() => handlePageChange(i)}>
                    {i}
                </Pagination.Item>,
            );
        }
        return pageNumbers;
    };

    // 환자 목록 데이터 안전하게 접근
    const patientList = patients?.data?.list || [];
    const totalCount = patients?.totalCount || 0;
    const myPatientCount = patients?.myPatientCount || 0;

    const handleClickData = (patient) => {
        if (!patient.myPatient) {
            let reasonPass = JSON.parse(sessionStorage.getItem("reasonPass")) || [];
            const token = sessionStorage.getItem("token");
            const vUid = jwtDecode(token).uuid;
            const existingEntry = reasonPass.find((entry) => entry.vUid === vUid && entry.ptUid === patient.uid);

            settingPatient(patient);
            nextPage();
        } else {
            settingPatient(patient);
            nextPage();
        }
    };

    const settingPatient = (patient) => {
        sessionStorage.setItem("patientUID", patient.uid);
    };

    const nextPage = () => {
        navigate(`/monitoring-cnr-h${hospitalId}`);
    };

    return (
        <>
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>환자관리</h2>
                    </div>
                    <hr className="bg-body-secondary mb-3" />
                </Row>

                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div id="width_relative_to_parent_" className="p-4 border-bottom bg-body hover-actions-trigger card-header">
                            <SearchBar onSearch={handleSearch} onReset={handleReset} initialSearchData={searchData} />
                        </div>

                        <div className="p-0 card-body">
                            <div className="p-4">
                                <PatientList patients={patientList} loading={loading} error={error} handleClickData={handleClickData} />

                                {totalCount > 0 && (
                                    <div className="d-flex justify-content-center p-2 mb-2">
                                        <div className="p-2 ">
                                            <Pagination>{renderPageNumbers()}</Pagination>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientListPage_CNR;
