import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import MuiDatePicker from "components/base/MuiDatePicker";
import { OPTION_LIST } from "constants/cardiacTargetTypeList";
import { TODAY } from "constants/monitoring";
import useConfirmDialog from "hooks/useConfirmDialog";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import calculateEndDate from "utils/calculateEndDate";
import PatientInfoRow from "components/PatientMng/PatientInfoRow";
import { useDoctors } from "hooks/counseling/useDoctors";
import { getTranslatedMessage } from "constants/alertMesseage";

//ETT정보 최초 등록 : 1차검사
const EttRegister = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const patientUID = sessionStorage.getItem("patientUID");
    const [validated, setValidated] = useState(false);
    const [doctorsList, setDoctorsList] = useState(null);
    const reDateBase = (searchParams.get("reDateBase") || "false") === "true" ? true : false;

    const showConfirmDialog = useConfirmDialog();

    const formRef = useRef(null);
    const dateInspectFirstRef = useRef(null);
    const dateInspectSecondRef = useRef(null);
    const dateInspectThirdRef = useRef(null);
    const dateBaseRef = useRef(null);
    const dateOccurRef = useRef(null);

    const { data: doctors, isLoading: isDoctorsLoading, error: doctorsError } = useDoctors();

    const [formData, setFormData] = useState({
        patientUID: patientUID,
        doctorId: "", // 담당의 고유키
        height: "", // 키
        weight: "", // 몸무게
        reDateBase: reDateBase, // 재발병 여부
        diseaseName: "", // 심장 질환명
        targetCategoryNo: "", // 대상구분 번호
        targetCategoryComments: "", // 대상구분 comments
        dateBase: "", // 기준일(퇴원일)
        dateOccur: "", // 발병일(진단일)
        dateInspectFirst: TODAY, // 1차 검사일
        dateInspectSecond: calculateEndDate(TODAY, "06"), // 2차 검사일
        dateInspectThird: calculateEndDate(TODAY, "12"), // 3차 검사일
        restingHeartRate: "", // 안정시 심박수
        maxHeartRate: "", // 최대 심박수
        riskGroup: "1", // 위험군
        METs: "", // 대사당량
        etcYn: false, // 기타질병 여부
        etc: "", // 기타질병명
    });

    // 담당의 목록 세팅
    useEffect(() => {
        if (doctors?.data) {
            setDoctorsList(doctors.data.list);
        }
    }, [doctors]);

    // 텍스트필드 입력 핸들러
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "etcYn") {
            const boolValue = value === "true";
            setFormData((prev) => ({
                ...prev,
                [name]: boolValue,
            }));
        } else if (name === "targetCategoryNo") {
            const selectedOption = OPTION_LIST.find((option) => option.no.toString() === value);
            setFormData((prev) => ({
                ...prev,
                [name]: value,
                targetCategoryComments: selectedOption ? selectedOption.title : "",
            }));
        } else {
            // 숫자만 허용하는 필드 목록과 각 필드의 최대 길이
            const numericFields = {
                height: 3,
                weight: 3,
                restingHeartRate: 3,
                maxHeartRate: 3,
            };

            if (name in numericFields) {
                // 숫자가 아닌 문자 제거 및 길이 제한
                const numericValue = value.replace(/[^0-9]/g, "").slice(0, numericFields[name]);
                setFormData((prev) => ({ ...prev, [name]: numericValue }));
            } else {
                setFormData((prev) => ({ ...prev, [name]: value }));
            }
        }
    };

    // 날짜필드 입력 핸들러
    const handleDateChange = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = formRef.current;

        setValidated(true); // 폼 제출 시 validated를 true로 설정

        if (
            form.checkValidity() === false ||
            !formData.targetCategoryNo ||
            !formData.dateInspectFirst ||
            !formData.dateInspectSecond ||
            !formData.dateInspectThird
        ) {
            e.stopPropagation();

            // 먼저 일반적인 입력 필드의 유효성을 검사
            const firstInvalidInput = form.querySelector(":invalid");
            if (firstInvalidInput) {
                firstInvalidInput.focus();
                return;
            }

            // 그 다음 날짜 필드 체크
            if (!formData.targetCategoryNo) {
                const translatedMessage = getTranslatedMessage("SELECT_TARGET_CATEGORY");
                toast.error(translatedMessage);
            } else if (!formData.dateBase && dateBaseRef.current) {
                dateBaseRef.current.focus();
            } else if (!formData.dateOccur && dateOccurRef.current) {
                dateOccurRef.current.focus();
            } else if (!formData.dateInspectFirst && dateInspectFirstRef.current) {
                dateInspectFirstRef.current.focus();
            } else if (!formData.dateInspectSecond && dateInspectSecondRef.current) {
                dateInspectSecondRef.current.focus();
            } else if (!formData.dateInspectThird && dateInspectThirdRef.current) {
                dateInspectThirdRef.current.focus();
            }

            return; // 유효성 검사 실패 시 여기서 함수 종료
        }

        //etcYn Y/N에 따라 서버에 전송할때 값 변경
        const submissionData = {
            ...formData,
            etc: formData.etcYn === true ? formData.etc : "",
        };

        showConfirmDialog({ text: "저장하시겠습니까?", icon: "question" }, () => submitEttData({ ...submissionData, patientUID: patientUID }));
    };

    const { mutate: submitEttData, isLoading: isSubmitting } = useMutation({
        mutationFn: (data) => fetchData("POST", "/v1/management/ett/regist", data),
        onSuccess: (data) => {
            sessionStorage.setItem("patientUID", data.data.patientUID);
            const translatedMessage = getTranslatedMessage("REGISTRATION_SUCCESS");
            toast.success(translatedMessage);
            navigate(`/ett/detail?cycle=1&programId=${data.data.programId}`, { replace: true });
        },
        onError: (error) => {
            console.error("Error submitting ETT data:", error);
            const translatedMessage = getTranslatedMessage("REGISTRATION_FAILED");
            toast.error(translatedMessage);
        },
    });

    return (
        <>
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>ETT 기록</h2>
                    </div>
                    <p className="text-body-tertiary lead">
                        운동부하 1차 검사 등록{reDateBase === true ? <span className="text-danger">(재발병)</span> : ""}
                    </p>
                    <hr className="bg-body-secondary mb-3" />
                </Row>

                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-4">
                                <PatientInfoRow />

                                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                    <div className="mt-3">
                                        <h4>기본정보</h4>
                                    </div>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={3} className="p-2">
                                            <Form.Group controlId="formName">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        담당의사 <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Select name="doctorId" onChange={handleInputChange} required>
                                                    <option value="">선택하세요</option>
                                                    {isDoctorsLoading ? (
                                                        <option>로딩 중...</option>
                                                    ) : doctorsError ? (
                                                        <option>에러 발생</option>
                                                    ) : doctorsList && doctorsList.length > 0 ? (
                                                        doctorsList.map((item, index) => (
                                                            <option key={index} value={item.doctorId}>
                                                                {item.department !== "" && item.department !== null ? `[${item.department}] ` : ""}
                                                                {item.name} ({item.userId})
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option>의사 목록이 없습니다</option>
                                                    )}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">담당교수를 선택해주세요.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="cm">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        키(cm) <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="height"
                                                    value={formData.height}
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 180 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                    pattern="\d*"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="kg">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        몸무게(kg) <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="weight"
                                                    value={formData.weight}
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 70 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                    pattern="\d*"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <div className="mt-6">
                                        <h4>질환정보</h4>
                                    </div>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="illness" className="mt-2">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        심장 질환명 <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="diseaseName"
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 심근경색, 심부전 등"
                                                    required
                                                    autoComplete="off"
                                                    maxLength={100}
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <div className="mt-2">
                                        <h5>
                                            대상구분 <span className="text-danger">*</span>
                                        </h5>
                                    </div>
                                    {validated && !formData.targetCategoryNo && (
                                        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                            대상구분을 선택해주세요.
                                        </Form.Control.Feedback>
                                    )}

                                    <ListGroup variant="flush" className=" overflow-y-auto" style={{ height: "270px" }}>
                                        <ListGroup.Item>
                                            <Row className="p-2 mb-2 align-middle" style={{ height: "45px" }}>
                                                {OPTION_LIST.map((option) => (
                                                    <Col sm={6} className="p-2" key={option.no}>
                                                        <Form.Check
                                                            type="radio"
                                                            id={`radio-${option.no}`}
                                                            label={`${option.no}. ${option.title}`}
                                                            name="targetCategoryNo"
                                                            value={option.no}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    발병일(진단일) <span className="text-danger">*</span>
                                                </h5>
                                            </div>
                                            <MuiDatePicker
                                                maxDate={TODAY}
                                                isInvalid={validated && !formData.dateOccur}
                                                onChange={(value) => handleDateChange("dateOccur", value)}
                                                ref={dateOccurRef}
                                            />
                                            {validated && !formData.dateOccur && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                        <Col xs lg={6} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    기준일(퇴원일) <span className="text-danger">*</span>
                                                </h5>
                                            </div>

                                            <MuiDatePicker
                                                maxDate={TODAY}
                                                isInvalid={validated && !formData.dateBase}
                                                onChange={(value) => handleDateChange("dateBase", value)}
                                                ref={dateBaseRef}
                                            />
                                            {validated && !formData.dateBase && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={4} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    1차 검사일 <span className="text-danger">*</span>
                                                </h5>
                                            </div>
                                            <MuiDatePicker
                                                maxDate={TODAY}
                                                value={TODAY}
                                                isInvalid={validated && !formData.dateInspectFirst}
                                                onChange={(value) => handleDateChange("dateInspectFirst", value)}
                                                ref={dateInspectFirstRef}
                                            />
                                            {validated && !formData.dateInspectFirst && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                        <Col xs lg={4} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    2차 검사일 <span className="text-danger">*</span>
                                                </h5>
                                            </div>
                                            <MuiDatePicker
                                                value={formData.dateInspectSecond}
                                                isInvalid={validated && !formData.dateInspectSecond}
                                                onChange={(value) => handleDateChange("dateInspectSecond", value)}
                                                ref={dateInspectSecondRef}
                                            />
                                            {validated && !formData.dateInspectSecond && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                        <Col xs lg={4} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    3차 검사일 <span className="text-danger">*</span>
                                                </h5>
                                            </div>
                                            <MuiDatePicker
                                                value={formData.dateInspectThird}
                                                isInvalid={validated && !formData.dateInspectThird}
                                                onChange={(value) => handleDateChange("dateInspectThird", value)}
                                                ref={dateInspectThirdRef}
                                            />
                                            {validated && !formData.dateInspectThird && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="illness">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        안정 시 심박수(Resting HR) <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    name="restingHeartRate"
                                                    value={formData.restingHeartRate}
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 89 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                    pattern="\d*"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="illness">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        최대 심박수(Maximal HR) <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="maxHeartRate"
                                                    value={formData.maxHeartRate}
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 110 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                    pattern="\d*"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group>
                                                <Form.Label className="p-0 mb-1">
                                                    <h5>
                                                        위험군 <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="danger_1"
                                                label="저위험군"
                                                defaultChecked
                                                value="1"
                                                name="riskGroup"
                                                onChange={handleInputChange}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="danger_2"
                                                label="중위험군"
                                                value="2"
                                                name="riskGroup"
                                                onChange={handleInputChange}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="danger_3"
                                                label="고위험군"
                                                name="riskGroup"
                                                value="3"
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="illness">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        METs <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={formData.METs}
                                                    onChange={(e) => {
                                                        let value = e.target.value.replace(/[^0-9.]/g, "");
                                                        if (value.includes(".")) {
                                                            const parts = value.split(".");
                                                            value = `${parts[0]}.${parts[1].slice(0, 1)}`;
                                                        } else if (value.length > 2) {
                                                            value = `${value.slice(0, -1)}.${value.slice(-1)}`;
                                                        }
                                                        handleInputChange({
                                                            target: { name: "METs", value },
                                                        });
                                                    }}
                                                    placeholder="ex) 6.7 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group>
                                                <Form.Label className="p-0 mb-1">
                                                    <h5>기타질병</h5>
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="other_N"
                                                label="없음"
                                                defaultChecked
                                                name="etcYn"
                                                value={false}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="other_Y"
                                                label="있음"
                                                name="etcYn"
                                                value={true}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Control
                                                type="text"
                                                name="etc"
                                                value={formData.etcYn ? formData.etc : ""}
                                                onChange={handleInputChange}
                                                placeholder="ex) 고혈압, 당뇨병 등"
                                                required={formData.etcYn}
                                                autoComplete="off"
                                                maxLength={100}
                                                disabled={!formData.etcYn}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <Button variant="secondary" className="ms-2" onClick={() => navigate(-1)}>
                                            취소
                                        </Button>
                                        <Button variant="primary" className="ms-2" type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? "저장 중..." : "저장하기"}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EttRegister;
