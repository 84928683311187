import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import useEttList from "hooks/counseling/useEttList";
import useConfirmDialog from "hooks/useConfirmDialog";
import usePatientInfo from "hooks/usePatientInfo";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MonitoringVerificationModal from "../../components/Monitoring/MonitoringVerificationModal";
import PatientMngSerchModal from "../../components/PatientMng/PatientMngSerchModal";
import PatientInfoRow from "components/PatientMng/PatientInfoRow";

const EttList = () => {
    const navigate = useNavigate();
    const patientUID = sessionStorage.getItem("patientUID");
    const token = sessionStorage.getItem("token");
    const showConfirmDialog = useConfirmDialog();

    const [searchResult, setSearchResult] = useState(null);
    const [ettIngCheck, setEttIngCheck] = useState(false); // 진행중인 재활프로그램이 있나 체크
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [isSerchModalOpen, setIsSerchModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [programId, setProgramId] = useState(null);

    const { data: ettListData, isLoading: isEttListLoading } = useEttList(selectedPatient?.patientUID);
    const { data: patientInfo, isLoading: isPatientInfoLoading } = usePatientInfo();

    // selectedPatient에 환자정보가 세팅되면 서버에 ett list 요청
    useEffect(() => {
        if (ettListData?.data) {
            setSearchResult(ettListData.data.list);
            setEttIngCheck(ettListData.data.newProgram);
        }
    }, [ettListData]);

    // 다시 목록페이지로 왔을때 이전 검색결과 호출
    useEffect(() => {
        // patientUID로 ettList 조회
        const storedPatientUID = sessionStorage.getItem("patientUID");

        if (storedPatientUID) {
            setSelectedPatient({ patientUID: storedPatientUID });
        }
    }, []);

    // patientInfo가 로드되면 selectedPatient 업데이트
    useEffect(() => {
        if (patientInfo && !isPatientInfoLoading) {
            setSelectedPatient((prevState) => ({
                ...prevState,
                ...patientInfo,
            }));
        }
    }, [patientInfo, isPatientInfoLoading]);

    // 검색버튼 -> 환자검색 모달창 호출
    const handleSearchButton = (reasonNo) => {
        setIsSerchModalOpen(true);
    };

    //검색 모달창에서 환자 선택시 이벤트
    const handlePatientSelect = (patient) => {
        setSelectedPatient(patient);
        sessionStorage.setItem("patientUID", patient.patientUID);
        setIsSerchModalOpen(false);
    };

    // ett정보 조회페이지 이동 : 담당의가 아닌경우 조회 사유 입력 모달창 호출
    const handleClickRow = (programId, doctorMatchingYN, cycle) => {
        // if (!doctorMatchingYN) {
        //     let queryPass = JSON.parse(sessionStorage.getItem("queryPass2")) || [];
        //     const userId = jwtDecode(token).uuid;
        //     const existingEntry = queryPass.find((entry) => entry.userId === userId && entry.patientUID === patientUID);
        //     setProgramId(programId);

        //     if (existingEntry) {
        //         navigate(`/ett/detail?cycle=1&programId=${programId}`);
        //     } else {
        //         queryPass.push({ userId, patientUID });
        //         sessionStorage.setItem("queryPass2", JSON.stringify(queryPass));
        //         setIsModalOpen(true);
        //     }
        // } else {
        navigate(`/ett/detail?cycle=${cycle}&programId=${programId}`);
        // }
    };

    // 사유 선택 후 전송 클릭
    const handleLoadMonitoringData = (reasonNo) => {
        verificationMutate(reasonNo);
        navigate(`/ett/detail?cycle=1&programId=${programId}`);
    };
    // 사유서 정보 전송
    const { mutate: verificationMutate } = useMutation({
        mutationFn: (reasonNo) =>
            fetchData("POST", "/v1/monitoring/patient/matching", {
                patientUID: patientUID,
                reasonNo,
            }),
    });

    return (
        <>
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>운동부하검사 기록</h2>
                    </div>
                    <hr className="bg-body-secondary mb-3" />
                </Row>

                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-4">
                                {/* <Row className="p-3 mb-1">
                                    <Col className="p-2 border d-flex align-items-center justify-content-center bg-body-highlight" sm={1}>
                                        <p className="mb-0 text-body-tertiary fw-bold">환자명</p>
                                    </Col>
                                    <Col className="p-2 border d-flex align-items-center justify-content-center" sm={2}>
                                        <p className="mb-0 text-body-dark fw-bold">{selectedPatient ? selectedPatient.name : "-"}</p>
                                    </Col>
                                    <Col className="p-2 border d-flex align-items-center justify-content-center bg-body-highlight" sm={1}>
                                        <p className="mb-0 text-body-tertiary fw-bold">생년월일</p>
                                    </Col>
                                    <Col className="p-2 border d-flex align-items-center justify-content-center" sm={2}>
                                        <p className="mb-0 text-body-dark fw-bold">{selectedPatient ? selectedPatient.birth : "-"}</p>
                                    </Col>
                                    <Col className="p-2 border d-flex align-items-center justify-content-center bg-body-highlight" sm={1}>
                                        <p className="mb-0 text-body-tertiary fw-bold">핸드폰번호</p>
                                    </Col>
                                    <Col className="p-2 border d-flex align-items-center justify-content-center" sm={2}>
                                        <p className="mb-0 text-body-dark fw-bold">{selectedPatient ? selectedPatient.phone : "-"}</p>
                                    </Col>
                                    <Col className="p-2 border d-flex align-items-center justify-content-center" sm={3}>
                                        <Button className="w-70" onClick={() => handleSearchButton()}>
                                            검색
                                        </Button>
                                    </Col>
                                </Row> */}
                                <PatientInfoRow />
                                {selectedPatient === null ? (
                                    <div className="order-1 order-xl-0 col-xl-12 col-12">
                                        <div className="mb-4 shadow-none border overflow-visible card">
                                            <div className="p-0 card-body">
                                                <div className="p-15 text-center" style={{ height: "250px" }}>
                                                    <p className="mb-0 text-body-tertiary">
                                                        <b>조회된 검색결과가 없습니다.</b>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="scrollbar ms-n1 ps-1">
                                        <Table bordered hover className="phoenix-table fs-9 mb-0 border-top">
                                            <thead className="bg-body-highlight">
                                                <tr>
                                                    <th
                                                        className="text-center text-body-tertiary align-middle"
                                                        style={{ width: "4%", minWidth: "18px" }}
                                                    >
                                                        NO
                                                    </th>
                                                    <th
                                                        className="text-center text-body-tertiary align-middle"
                                                        style={{ width: "10%", minWidth: "100px" }}
                                                    >
                                                        재발병
                                                    </th>
                                                    <th
                                                        className="text-center text-body-tertiary align-middle"
                                                        style={{ width: "16%", minWidth: "100px" }}
                                                    >
                                                        프로그램명
                                                    </th>
                                                    <th
                                                        className="text-center text-body-tertiary align-middle"
                                                        style={{ width: "10%", minWidth: "100px" }}
                                                    >
                                                        발병일(진단일)
                                                    </th>
                                                    <th
                                                        className="text-center text-body-tertiary align-middle"
                                                        style={{ width: "10%", minWidth: "100px" }}
                                                    >
                                                        기준일(퇴원일)
                                                    </th>
                                                    <th
                                                        className="text-center text-body-tertiary align-middle"
                                                        style={{ width: "14%", minWidth: "100px" }}
                                                    >
                                                        심장 질환명
                                                    </th>
                                                    <th
                                                        className="text-center text-body-tertiary align-middle"
                                                        style={{ width: "10%", minWidth: "100px" }}
                                                    >
                                                        1차 검사일
                                                    </th>
                                                    <th
                                                        className="text-center text-body-tertiary align-middle"
                                                        style={{ width: "10%", minWidth: "100px" }}
                                                    >
                                                        2차 검사일
                                                    </th>
                                                    <th
                                                        className="text-center text-body-tertiary align-middle"
                                                        style={{ width: "10%", minWidth: "100px" }}
                                                    >
                                                        3차 검사일
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!searchResult || searchResult.length <= 0 ? (
                                                    <tr>
                                                        <td colSpan="9" className="text-center text-body-tertiary">
                                                            {isEttListLoading ? (
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <Spinner animation="border" role="status">
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </Spinner>
                                                                </div>
                                                            ) : (
                                                                "조회된 검색결과가 없습니다."
                                                            )}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    searchResult.map((item, index) => {
                                                        return (
                                                            <tr
                                                                key={index}
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    const cycle =
                                                                        item.thirdCycle.date && item.thirdCycle.date != "submit"
                                                                            ? 3
                                                                            : item.secondCycle.date && item.secondCycle.date != "submit"
                                                                            ? 2
                                                                            : 1;
                                                                    handleClickRow(item.programId, item.doctorMatchingYN, cycle);
                                                                }}
                                                            >
                                                                <td className="text-center">{searchResult.length - index}</td>
                                                                <td className="text-center">{item.reDate !== null ? item.reDate : "-"}</td>
                                                                <td className="text-center">{item.programName !== null ? item.programName : "-"}</td>
                                                                <td className="text-center">
                                                                    {item.firstCycle.dateOccur !== null ? item.firstCycle.dateOccur : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item.firstCycle.dateBase !== null ? item.firstCycle.dateBase : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item.firstCycle.diseaseName !== null ? item.firstCycle.diseaseName : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item.firstCycle.date !== null ? item.firstCycle.date : "-"}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item.secondCycle.date === "submit" ? (
                                                                        <Button
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                navigate(
                                                                                    `/ett/modify?cycle=2&programId=${item.programId}&accessType=list`,
                                                                                );
                                                                            }}
                                                                            className="btn btn-primary"
                                                                        >
                                                                            등록하기
                                                                        </Button>
                                                                    ) : item.secondCycle.date !== null ? (
                                                                        item.secondCycle.date
                                                                    ) : (
                                                                        "-"
                                                                    )}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item.thirdCycle.date === "submit" ? (
                                                                        <Button
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                navigate(
                                                                                    `/ett/modify?cycle=3&programId=${item.programId}&accessType=list`,
                                                                                );
                                                                            }}
                                                                            className="btn btn-primary"
                                                                        >
                                                                            등록하기
                                                                        </Button>
                                                                    ) : item.thirdCycle.date !== null ? (
                                                                        item.thirdCycle.date
                                                                    ) : (
                                                                        "-"
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </Table>

                                        <Row className="mt-3">
                                            <Col sm={6}>
                                                <div className="d-flex justify-content-start">
                                                    <Button
                                                        variant="secondary"
                                                        onClick={() => {
                                                            navigate(`/ett/patients`);
                                                        }}
                                                    >
                                                        환자 목록
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="d-flex justify-content-end">
                                                    {searchResult ? (
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => {
                                                                if (!ettIngCheck) {
                                                                    showConfirmDialog(
                                                                        {
                                                                            text: "기존에 진행중인 프로그램이 있습니다.\n 계속 진행하시겠습니까?",
                                                                            icon: "question",
                                                                        },
                                                                        () => navigate(`/ett/register?reDateBase=true`),
                                                                    );
                                                                } else {
                                                                    navigate(`/ett/register?reDateBase=true`);
                                                                }
                                                            }}
                                                        >
                                                            재발병 재활프로그램 등록
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <Button
                                                        className="ms-3"
                                                        variant="primary"
                                                        onClick={() => {
                                                            if (!ettIngCheck) {
                                                                showConfirmDialog(
                                                                    {
                                                                        text: "기존에 진행중인 프로그램이 있습니다.\n 계속 진행하시겠습니까?",
                                                                        icon: "question",
                                                                    },
                                                                    () => navigate(`/ett/register?reDateBase=false`),
                                                                );
                                                            } else {
                                                                navigate(`/ett/register?reDateBase=false`);
                                                            }
                                                        }}
                                                    >
                                                        재활프로그램 등록
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isSerchModalOpen && (
                <PatientMngSerchModal
                    isModalOpen={isSerchModalOpen}
                    handleCloseModal={() => setIsSerchModalOpen(false)}
                    onPatientSelect={handlePatientSelect}
                />
            )}
            {isModalOpen && (
                <MonitoringVerificationModal
                    isModalOpen={isModalOpen}
                    handleCloseModal={() => setIsModalOpen(false)}
                    handleLoadMonitoringData={handleLoadMonitoringData}
                />
            )}
        </>
    );
};

export default EttList;
