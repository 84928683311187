import {
    ERROR_ID_EMPTY,
    DULICATED_ID,
    ERROR_PASSWORD_MATCH,
    ERROR_PASSWORD_VALID,
    ERROR_PHONE_NUMBER,
    NAME_CHECK,
    ERROR_ID_CHECK,
    DULICATED_TEST_ID,
    INVALID_PHONE_NUMBER_LENGTH,
} from "constants/errorMessage";
import { NAME_REGEX, NUMBER_ONLY_REGEX, PASSWORD_REGEX, ID_REGEX } from "constants/regex";
import checkIsEmpty from "./checkIsEmpty";
import { toast } from "sonner";

// 비밀번호 유효성 검사 함수
export const validatePassword = (password) => {
    if (!checkIsEmpty(password)) {
        return ERROR_PASSWORD_VALID;
    }
    if (!PASSWORD_REGEX.test(password)) {
        return ERROR_PASSWORD_VALID;
    }
    return "";
};

// 비밀번호 일치 검사 함수
export const validatePasswordMatch = (password, passwordCheck) => {
    if (password !== passwordCheck) {
        return ERROR_PASSWORD_MATCH;
    }
    return "";
};

// 유효성 검사 유틸 함수
export const validateFormData = ({ userId, name, phone, userPassword, userPasswordCheck }, isValidId, setErrors) => {
    let hasError = false;
    const newErrors = {};

    // 아이디 검사
    if (!checkIsEmpty(userId)) {
        newErrors.userId = ERROR_ID_EMPTY;
        hasError = true;
    } else if (!ID_REGEX.test(userId)) {
        newErrors.userId = ERROR_ID_CHECK;
        hasError = true;
    } else if (!isValidId) {
        newErrors.userId = DULICATED_TEST_ID;
        hasError = true;
    }

    // 이름 검사
    if (!(checkIsEmpty(name) && NAME_REGEX.test(name))) {
        newErrors.name = NAME_CHECK;
        hasError = true;
    }

    // 전화번호 검사
    if (!checkIsEmpty(phone)) {
        newErrors.phone = ERROR_PHONE_NUMBER;
        hasError = true;
    }

    if (!NUMBER_ONLY_REGEX.test(phone)) {
        newErrors.phone = INVALID_PHONE_NUMBER_LENGTH;
        hasError = true;
    }

    // 비밀번호 검사
    if (!checkIsEmpty(userPassword) || !PASSWORD_REGEX.test(userPassword)) {
        newErrors.userPassword = ERROR_PASSWORD_VALID;
        hasError = true;
    }

    // 비밀번호 확인 검사
    if (userPassword !== userPasswordCheck) {
        newErrors.userPasswordCheck = ERROR_PASSWORD_MATCH;
        hasError = true;
    }

    setErrors(newErrors);

    return !hasError;
};
