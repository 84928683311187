import { useQuery } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { toast } from "sonner";
import { getTranslatedMessage } from "constants/alertMesseage";

const useCounselingList = (targetId, programId, type, etc) => {
    return useQuery({
        queryKey: ["counselingList", targetId, programId, type, etc],
        queryFn: () => fetchData("POST", "/v1/management/load/counsel", { targetId, programId, type, etc }),
        onSuccess: (data) => {
            // console.log("Counseling list fetched:", data);
        },
        onError: (error) => {
            console.error("Error fetching counseling list:", error);
            const translatedMessage = getTranslatedMessage("DATA_FETCH_FAILED");
            toast.error(translatedMessage);
        },
    });
};

export default useCounselingList;
