import { getLanguage } from "utils/language";

export const messeage = {
    TIME_EXTENSION_LIMIT: {
        ko: "시간 연장 한도에 도달했습니다.",
        en: "Time extension limit has been reached.",
        ja: "時間延長の制限に達しました。",
    },
    REGISTRATION_SUCCESS: {
        ko: "등록되었습니다.",
        en: "Registration was successful.",
        ja: "登録が完了しました。",
    },
    REGISTRATION_FAILED: {
        ko: "등록에 실패했습니다.",
        en: "Registration failed.",
        ja: "登録に失敗しました。",
    },
    UPDATE_SUCCESS: {
        ko: "수정되었습니다.",
        en: "Successfully updated.",
        ja: "更新されました。",
    },
    UPDATE_FAILED: {
        ko: "수정에 실패하였습니다.",
        en: "Update failed.",
        ja: "更新に失敗しました。",
    },
    DATA_FETCH_FAILED: {
        ko: "정보를 불러오는데 실패했습니다.",
        en: "Failed to retrieve information.",
        ja: "情報の取得に失敗しました。",
    },
    INVALID_ACCESS: {
        ko: "잘못된 접근입니다.",
        en: "Invalid access.",
        ja: "無効なアクセスです。",
    },
    LOGIN_FAIL: {
        ko: "로그인에 실패했습니다. 다시 시도해주세요.",
        en: "Login failed. Please try again.",
        ja: "ログインに失敗しました。もう一度お試しください。",
    },
    PLEASE_CHECK_ID_AND_PW: {
        ko: "아이디/비밀번호를 확인해주세요.",
        en: "Please check your ID and password.",
        ja: "IDとパスワードを確認してください。",
    },
    PLEASE_ENTER_ID_AND_PW: {
        ko: "아이디/비밀번호를 입력해주세요.",
        en: "Please enter your ID and password.",
        ja: "IDとパスワードを入力してください。",
    },
    AUTH_NUMBER_INCORRECT: {
        ko: "인증번호가 맞지 않습니다.",
        en: "The verification code is incorrect.",
        ja: "認証コードが正しくありません。",
    },
    DUPLICATE_ID_EXISTS: {
        ko: "중복된 아이디가 존재합니다.",
        en: "This ID already exists.",
        ja: "このIDは既に存在します。",
    },
    ERROR_ID_EMPTY: {
        ko: "아이디를 입력해주세요.",
        en: "Please enter an ID.",
        ja: "IDを入力してください。",
    },
    ERROR_ID_CHECK: {
        ko: "아이디를 다시 확인해주세요.",
        en: "Please check the ID again.",
        ja: "IDを再確認してください。",
    },
    EMPTY_INPUT: {
        ko: "필수 입력값을 입력해주세요.",
        en: "Please enter the required information.",
        ja: "必須項目を入力してください。",
    },
    ERROR_PASSWORD_MATCH: {
        ko: "비밀번호가 일치하지 않습니다.",
        en: "Passwords do not match.",
        ja: "パスワードが一致しません。",
    },
    ERROR_COPORATION_NUMBER: {
        ko: "사업자등록번호는 숫자만 입력해주세요.",
        en: "Please enter only numbers for the business registration number.",
        ja: "事業者登録番号は数字のみ入力してください。",
    },
    PASSWORD_CHANGED_SUCCESS: {
        ko: "비밀번호가 변경되었습니다.",
        en: "Password has been changed.",
        ja: "パスワードが変更されました。",
    },
    PASSWORD_CHANGE_FAILED: {
        ko: "비밀번호 변경에 실패했습니다.",
        en: "Password change failed.",
        ja: "パスワードの変更に失敗しました。",
    },
    INVALID_OR_MISMATCHED_PASSWORD: {
        ko: "비밀번호가 유효하지 않거나 일치하지 않습니다.",
        en: "The password is invalid or does not match.",
        ja: "パスワードが無効または一致しません。",
    },
    NAME_MIN_LENGTH_REQUIRED: {
        ko: "이름은 2자 이상 입력해주세요.",
        en: "Please enter at least 2 characters for the name.",
        ja: "名前は2文字以上入力してください。",
    },
    INVALID_PHONE_NUMBER_LENGTH: {
        ko: "핸드폰 번호는 중간 3~4자리, 마지막 4자리 숫자로 입력해주세요.",
        en: "Please enter 3-4 digits in the middle and 4 digits at the end for the phone number.",
        ja: "携帯番号の中間は3～4桁、最後は4桁の数字を入力してください。",
    },
    SELECT_TARGET_CATEGORY: {
        ko: "대상구분을 선택해 주세요.",
        en: "Please select a target category.",
        ja: "対象区分を選択してください。",
    },
    WORKOUT_DURATION_CONSTRAINT: {
        ko: "운동 기간은 최소 1일부터 최대 12주까지 조회 할 수 있습니다.",
        en: "Workout duration can be checked from a minimum of 1 day to a maximum of 12 weeks.",
        ja: "運動期間は最小1日から最大12週間まで確認できます。",
    },
    DATE_CANNOT_BE_BEFORE_BIRTH: {
        ko: "생년월일보다 이전 날짜를 조회할 수 없습니다.",
        en: "You cannot search for a date before the date of birth.",
        ja: "生年月日より前の日付を検索することはできません。",
    },

    // 새로운 메시지를 추가할 때:
    // NEW_MESSAGE_KEY: {
    //     ko: "한국어 메시지",
    //     en: "English message",
    //     ja: "日本語メッセージ",
    // },
};

export const getTranslatedMessage = (key) => {
    let lang = getLanguage() || "ko";
    if (!messeage[key]) {
        console.warn(`No message found for key: ${key}`);
        return key; // 키를 찾지 못한 경우 키 자체를 반환
    }
    return messeage[key][lang] || messeage[key]["ko"]; // 해당 언어가 없으면 한국어로 폴백
};
