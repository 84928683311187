import CustomTooltip from "components/MonitoringDetail/CustomTooltip";
import CustomLegend from "components/MonitoringDetail/CustomLegend";
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Dot,
} from "recharts";
import formatWeekLabel from "utils/formatWeekLabel";
import FeatherIcon from "feather-icons-react";
import WorkoutSummary_2 from "components/WorkoutResult/WorkoutSummary_2";
import { Card } from "react-bootstrap";

const WeeklySquat = ({ squatResult }) => {
    // 데이터가 없거나 필요한 속성이 없을 때 처리
    if (!squatResult || !squatResult.workout || !squatResult.heartRate) {
        return <div>조회된 검색결과가 없습니다.</div>;
    }

    // Custom Tooltip 컴포넌트
    const CustomSquatTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const { workoutCount, squatSet, squatCount } = payload[0].payload;
            return (
                <CustomTooltip
                    label={label}
                    items={[
                        { color: "#396FDF", text: `운동횟수 : ${workoutCount}회` },
                        { color: "#11a93d", text: `운동세트 : ${squatSet}세트` },
                        { color: "#e23126", text: `측정횟수 : ${squatCount}회` },
                    ]}
                />
            );
        }
        return null;
    };

    const CustomAreaTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const { ettMax, max, min } = payload[0].payload;
            return (
                <CustomTooltip
                    label={label}
                    items={[
                        { color: "#E23126", text: `ETT최대심박수 : ${ettMax}` },
                        { color: "#BE1D95", text: `최대심박수 : ${max}` },
                        { color: "#353963", text: `최소심박수 : ${min}` },
                    ]}
                />
            );
        }
        return null;
    };

    return (
        <div className="mt-5">
            <h1 className="mb-2">스쿼트</h1>
            <Card className="p-4 mb-4">
                <WorkoutSummary_2 hTit="운동현황">
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={squatResult.workout} margin={{ right: 35 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tickFormatter={(value, index) => formatWeekLabel(index)} interval={6} scale="point" />
                            <YAxis />
                            <Tooltip content={<CustomSquatTooltip />} />
                            <Legend content={<CustomLegend />} colors={["#396FDF", "#11A93D", "#E23126"]} verticalAlign="top" align="right" />
                            <Bar dataKey="workoutCount" stackId="a" fill="#396FDF" name="운동횟수" />
                            <Bar dataKey="squatSet" stackId="a" fill="#11A93D" name="운동세트" />
                            <Bar dataKey="squatCount" stackId="a" fill="#E23126" name="측정횟수" />
                        </BarChart>
                    </ResponsiveContainer>
                </WorkoutSummary_2>

                <WorkoutSummary_2 hTit="최소/최대 심박수(bpm)">
                    <ResponsiveContainer width="100%" height={300}>
                        <ComposedChart data={squatResult.heartRate} margin={{ right: 35 }}>
                            <defs>
                                <linearGradient id="gradientMax" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(190,29,149,1)" stopOpacity={1} />
                                    <stop offset="100%" stopColor="rgba(190,29,149,0)" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="gradientMin" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="rgba(53,57,99,1)" stopOpacity={1} />
                                    <stop offset="100%" stopColor="rgba(53,57,99,0)" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="date" tickFormatter={(value, index) => formatWeekLabel(index)} interval={6} />
                            <YAxis />
                            <Tooltip content={<CustomAreaTooltip />} />
                            <Legend colors={["#E23126", "#BE1D95", "#353963"]} verticalAlign="top" align="right" />
                            <Line type="monotone" dataKey="ettMax" stroke="#E23126" strokeWidth={2} fill="none" name="ETT최대심박수" dot={false} />
                            <Area
                                type="monotone"
                                dataKey="max"
                                stroke="#BE1D95"
                                strokeWidth={2}
                                fill="url(#gradientMax)"
                                name="최대심박수"
                                dot={false}
                            />
                            <Area
                                type="monotone"
                                dataKey="min"
                                stroke="#353963"
                                strokeWidth={2}
                                fill="url(#gradientMin)"
                                name="최소심박수"
                                dot={false}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </WorkoutSummary_2>
            </Card>
        </div>
    );
};

export default WeeklySquat;
