import { Table, Row } from "react-bootstrap";

const HospitalInfoTable = ({ hospitalInfo }) => {
    return (
        <>
            <Row className="g-2">
                <div className="mb-3">
                    <h3>기관 정보</h3>
                </div>
            </Row>

            <div className="scrollbar ms-n1 ps-1">
                <Table bordered hover className="phoenix-table fs-9 mb-0 border-top">
                    <thead className="bg-body-highlight">
                        <tr>
                            <th className="text-center text-body-tertiary" style={{ width: "20%", minWidth: "100px" }}>
                                소속 병원
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "20%", minWidth: "100px" }}>
                                관리자
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "20%", minWidth: "100px" }}>
                                사업자등록번호
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "20%", minWidth: "100px" }}>
                                주소
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "20%", minWidth: "100px" }}>
                                비고
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">{hospitalInfo.hospitalName}</td>
                            <td className="text-center">{hospitalInfo.adminName}</td>
                            <td className="text-center">{hospitalInfo.coporationNumber ? hospitalInfo.coporationNumber : ""}</td>
                            <td className="text-center">{hospitalInfo.address ? hospitalInfo.address : "-"}</td>
                            <td className="text-center">{hospitalInfo.comments ? hospitalInfo.comments : "-"}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default HospitalInfoTable;
