import { useEffect, useState } from "react";
import { setLanguage, getLanguage } from "utils/language";

const GTranslate = () => {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        // GTranslate 설정 추가
        window.gtranslateSettings = {
            default_language: "ko",
            detect_browser_language: true,
            languages: ["ko", "ja", "en"],
            wrapper_selector: ".gtranslate_wrapper",
            switcher_horizontal_position: "right",
            switcher_vertical_position: "bottom",
            float_switcher_open_direction: "top",
            switcher_text_color: "#000",
            switcher_arrow_color: "#000",
            switcher_border_color: "#000",
            switcher_background_color: "#fff",
            switcher_background_shadow_color: "#efefef",
            switcher_background_hover_color: "#fff",
            dropdown_text_color: "#000",
            dropdown_hover_color: "#fff",
            dropdown_background_color: "#eee",
            ignore_classes: ["notranslate"],
        };

        // GTranslate 스크립트 추가
        const script = document.createElement("script");
        script.src = "https://cdn.gtranslate.net/widgets/latest/float.js";
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            setIsInitialized(true);
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (isInitialized) {
            const checkLanguageChange = () => {
                const googtrans = document.cookie.split("; ").find((row) => row.startsWith("googtrans="));
                let currentLang = "ko"; // 기본값

                if (googtrans) {
                    const langCode = googtrans.split("/")[2];
                    currentLang = langCode;
                }

                if (currentLang !== getLanguage()) {
                    setLanguage(currentLang);
                    window.dispatchEvent(new CustomEvent("languageChanged", { detail: currentLang }));
                }
            };

            // 초기 언어 설정
            checkLanguageChange();

            // MutationObserver 설정
            const observer = new MutationObserver(() => {
                checkLanguageChange();
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });

            // 주기적으로 쿠키 변경 확인 (백업 방법)
            // const intervalId = setInterval(checkLanguageChange, 10000);

            return () => {
                observer.disconnect();
                // clearInterval(intervalId);
            };
        }
    }, [isInitialized]);

    return <div className="gtranslate_wrapper"></div>;
};

export default GTranslate;
