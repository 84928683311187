import { Container } from "react-bootstrap";

const DailySummary = ({ summaryData }) => {
    const summaryItems = [
        {
            value: summaryData.totalTime,
            description: "전체 운동시간",
            cls: "border-bottom border-end border-translucent p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent",
        },
        {
            value: summaryData.stretchingTime,
            description: "스트레칭 운동시간",
            cls: "border-bottom border-end-md border-translucent p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent",
        },
        {
            value: summaryData.walkingTime,
            description: "걷기 운동시간",
            cls: "border-bottom border-end border-end-md border-translucent p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent",
        },
        {
            value: summaryData.squatTime,
            description: `스쿼트 운동`,
            cls: "border-bottom border-end-lg-0 border-translucent p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent",
        },
        {
            value: summaryData.heartRate,
            description: "최소/대 심박수(bpm)",
            cls: "border-end border-bottom border-bottom-md-0 border-translucent p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent",
        },
        {
            value: summaryData.targetHeartRate,
            description: "목표 심박수(bpm)",
            cls: "border-end-md border-bottom border-bottom-md-0 border-translucent p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent",
        },
        {
            value: summaryData.restingSelf,
            description: "휴식시간(시간/회)",
            cls: "border-end border-translucent p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent",
        },
        {
            value: summaryData.restingDanger,
            description: "안정시간(시간/회)",
            cls: "border-end-lg-0 border-translucent p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent",
        },
    ];

    return (
        <>
            <div className="mt-5">
                <h1 className="mb-2">일일 운동현황</h1>
                <Container className="p-2 border rounded">
                    <section>
                        <div className="container-small px-lg-7 px-xxl-3">
                            <div className="g-0 row">
                                {summaryItems.map((item, index) => (
                                    <div key={index} className="col-md-3 col-6">
                                        <div className={item.cls}>
                                            <div>
                                                <h3 className="mb-1 fw-semibold text-center">{item.value}</h3>
                                                <h5 className="text-body-tertiary fw-semibold text-center">{item.description}</h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </Container>
            </div>
        </>
    );
};

export default DailySummary;
