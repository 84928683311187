import React from "react";
import OperatorSetting from "components/settings/admin/OperatorSetting";
import OperatorRegister from "components/settings/admin/OperatorRegister";
import OperatorDetail from "components/settings/admin/OperatorDetail";
import StaffSetting from "components/settings/admin/StaffSetting";
import StaffRegister from "components/settings/admin/StaffRegister";
import StaffDetail from "components/settings/admin/StaffDetail";

import MyOperatorDetail from "components/settings/operator/MyOperatorDetail";
import OperatorForStaffRegister from "components/settings/operator/StaffRegister";
import OperatorForStaffDetail from "components/settings/operator/StaffDetail";
import OperatorForStaffSetting from "components/settings/operator/StaffSetting";

import MyStaffDetail from "components/settings/staff/MyStaffDetail";

// 관리자 화면
export const IdxOperatorSetting = () => {
    return <OperatorSetting />;
};
export const IdxOperatorRegister = () => {
    return <OperatorRegister />;
};
export const IdxOperatorDetail = () => {
    return <OperatorDetail />;
};
export const IdxStaffSetting = () => {
    return <StaffSetting />;
};
export const IdxStaffRegister = () => {
    return <StaffRegister />;
};
export const IdxStaffDetail = () => {
    return <StaffDetail />;
};

// 담당교수 화면
export const IdxMyOperatorDetail = () => {
    return <MyOperatorDetail />;
};
export const IdxOperatorForStaffRegister = () => {
    return <OperatorForStaffRegister />;
};
export const IdxOperatorForStaffDetail = () => {
    return <OperatorForStaffDetail />;
};
export const IdxOperatorForStaffSetting = () => {
    return <OperatorForStaffSetting />;
};

// 실무자 화면
export const IdxMyStaffDetail = () => {
    return <MyStaffDetail />;
};
